import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
    SfAccordionModule,
    SfButtonModule,
    SfCardModule,
    SfCheckboxModule,
    SfChipsModule,
    SfDialogModule,
    SfElevationModule,
    SfIconButtonModule,
    SfIconModule,
    SfInputModule,
    SfMenuModule,
    SfSelectModule,
    SfSnackBarModule,
    SfTypographyModule,
} from '@safire/components';
import {SharedMultiLangModule} from '@ew/shared/components/multi-lang';
import {SharedPipePascalToUppercaseModule} from '@ew/shared/pipe/pascal-to-uppercase';

import {LevelBaseComponent} from './components/level-base/level-base.component';
import {CurrencyComponent} from './components/level-configurator-modal/currency/currency.component';
import {LevelConfiguratorModalComponent} from './components/level-configurator-modal/level-configurator-modal.component';
import {PricingComponent} from './components/level-configurator-modal/pricing/pricing.component';
import {LevelCreateComponent} from './components/level-create/level-create.component';
import {LevelListComponent} from './components/level-list/level-list.component';
import {
    DynamicColumnsFormComponent,
} from './components/shared/components/dynamic-columns-form/dynamic-columns-form.component';
import {SharedPipeTranslateNameModule} from '@ew/shared/pipe/translate-name';
// eslint-disable-next-line max-len
import {AddonConfigurationModalComponent} from './components/shared/components/addon-configuration-modal/addon-configuration-modal.component';

@NgModule({
    imports: [
        CommonModule,
        SfCardModule,
        SfIconModule,
        SfTypographyModule,
        SfButtonModule,
        SfChipsModule,
        SfInputModule,
        SfIconButtonModule,
        SfAccordionModule,
        SfCheckboxModule,
        SfMenuModule,
        FlexLayoutModule,
        SfSnackBarModule,
        SfElevationModule,
        ReactiveFormsModule,
        TranslateModule,
        SfDialogModule,
        SfSelectModule,
        SharedMultiLangModule,
        SharedPipePascalToUppercaseModule,
        SharedPipeTranslateNameModule
    ],
    declarations: [
        DynamicColumnsFormComponent,
        LevelListComponent,
        LevelCreateComponent,
        LevelBaseComponent,
        LevelConfiguratorModalComponent,
        CurrencyComponent,
        PricingComponent,
        AddonConfigurationModalComponent
    ],
    exports: [
        LevelListComponent,
        LevelCreateComponent,
        SfSnackBarModule,
        TranslateModule],
    entryComponents: [LevelConfiguratorModalComponent]
})
export class PcLevelsModule {
}
