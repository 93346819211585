import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { SfFormUtil } from '@safire/angular-utils';
import {
    Configurator, ConfiguratorPrice, ControlOptions,
    Country, DataTypeEnum, DynamicColumn, DynamicValue, DynamicValueModal, DynamicValueModel,
    Image, LevelModel, MultiLanguages, NotificationService, ProductFormBuilder,
    ProductItem, ProductItemModel, ProductStateEnum, ProductTree, ProductTreeList
} from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';
import {Observable} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProductFacadeService } from '../../../services/product-facade.service';
import { MULTI_LANG_FIELD_CONFIG, SPECIFICATIONS_FIELD_CONFIG } from '../../../constants/items.constant';
import {omit} from 'lodash-es';

@UntilDestroy()
@Component({
    selector: 'ew-item-creation',
    templateUrl: './item-creation.component.html',
    styleUrls: ['./item-creation.component.scss'],
})
export class ItemCreationComponent extends FormBaseComponent implements OnInit {
  selectedLanguageControl = new FormControl();
  languages: string[] = Object.keys(new MultiLanguages());
  multiLangFieldConfigs: ControlOptions[];
  specificationsFieldConfigs: ControlOptions[];
  productImageUrls: string[] = [];
  dynamicNamesExist: boolean;
  configuratorDynamicValueForms: FormGroup[] = [];
  packageDynamicValueForms: FormGroup[] = [];
  configuratorState$: Observable<Configurator>;
  productTreeState$: Observable<ProductTree>;
  dataTypes = DataTypeEnum;
  countriesMap: Record<string, Country> = {};
  itemImages: string[] = [];

  constructor(
    private productFacadeService: ProductFacadeService, private translateService: TranslateService,
    private fb: FormBuilder, private activatedRoute: ActivatedRoute, private route: Router,
    private notify: NotificationService
  ) {
  	super();
  	this.configureForm(ProductFormBuilder.productItemInitializer());
  }

  ngOnInit(): void {
  	this.configuratorState$ = this.productFacadeService.specificStateChange<Configurator>(ProductStateEnum.CONFIGURATOR)
  		.pipe(map((configurators) => configurators[0]));
  	this.productTreeState$ = this.productFacadeService.specificStateChange<ProductTreeList>(ProductStateEnum.PRODUCT);
  	this.fetchDependentData();
  	this.selectedLanguageControl.setValue(this.translateService.currentLang);
  	this.multiLangFieldConfigs = MULTI_LANG_FIELD_CONFIG;
  	this.specificationsFieldConfigs = SPECIFICATIONS_FIELD_CONFIG;
  }

  fetchDependentData(): void {
  	this.productFacadeService.fetchProductItemCreationDeps(this.productTreeIdRouteParam, this.productItemIdRouteParam)
  		.subscribe(() => this.setupForm());
  }

  private setupForm(): void {
  	const productTree: ProductTreeList = this.productFacadeService.getSpecificState<ProductTreeList>(ProductStateEnum.PRODUCT);
  	const configurator = this.productFacadeService.getSpecificState<Configurator>(ProductStateEnum.CONFIGURATOR)[0];
  	this.form.get('packageId').setValue(productTree.packageId);
  	configurator?.dynamicColumns.forEach((column, i) => {
  		this.pushDynamicColumnForm(column, i);
  	});
  	this.configuratorDynamicValueForms = this.dynamicValuesFormArray.controls as FormGroup[];
  	this.setPricingControls(configurator.countries, configurator.configuratorPriceTypes);
  	this.setDynamicColumnsFromProductTree(productTree);
  	this.updateImageFormValidators();
  	this.patchProductItemFormData();
  }

  private patchProductItemFormData(): void {
  	const productItem: ProductItem = this.productFacadeService.getSpecificState<ProductItem>(ProductStateEnum.PRODUCT_ITEM);
  	if (this.productItemIdRouteParam && productItem) {
  		productItem?.prices.forEach((price, i) => {
  			this.pricesFormArray.controls[i].patchValue(price);
  		});
  		['name', 'id', 'active'].forEach(attr => {
  			this.props(attr).patchValue(productItem[attr]);
  		});
  		this.patchImageIds(productItem);
  		this.patchDynamicValue(productItem);
  		this.itemImages = productItem.images.map((image) => image.urls[0]);
  	}
  }

  patchDynamicValue(productItem: ProductItem): void {
  	this.dynamicValuesFormArray.controls.forEach(control => {
  		control.patchValue(new DynamicValueModal());
  		const dynamicValue: DynamicValue =
          productItem?.dynamicValues.find(value => value?.dynamicColumnId === control?.value?.dynamicColumnId);
  		!!dynamicValue && control.patchValue(dynamicValue);
  	});
  }

  patchImageIds(productItem: ProductItem): void {
  	productItem?.images.forEach(image => {
  		this.pushImageIdToFormArrays(image.id);
  	});
  }

  copyContent(copyType: string): void {
  	this.productFacadeService.copyContent(this.form, copyType, this.selectedLanguageControl.value);
  }

  dynamicValueLanguageErrors(columnIndex: number): string[] {
  	return this.languages.filter((lang) => {
  		const control = (this.form.get('dynamicValues') as FormArray).at(columnIndex)?.get('name')?.get(lang);
  		return control?.touched && control?.invalid;
  	});
  }

  pushImageIdToFormArrays(imageId: string): void {
  	this.imagesFormArray.push(
  		this.fb.group({
  			id: imageId,
  			// eslint-disable-next-line @typescript-eslint/naming-convention
  			_destroy: undefined,
  		})
  	);
  	this.imageIdsFormArray.push(this.fb.control(imageId));
  }

  uploadImage(file: File): Observable<Image> {
  	return this.productFacadeService.uploadImage(file).pipe(
  		tap((response) => {
  			this.pushImageIdToFormArrays(response.id);
  		})
  	);
  }

  removeImage(index: number): void {
  	if (!this.form.get('id').value) {
  		this.imagesFormArray.removeAt(index);
  		this.imageIdsFormArray.removeAt(index);
  	} else {
  		// eslint-disable-next-line @typescript-eslint/naming-convention
  		this.imagesFormArray.at(index).patchValue({ _destroy: true });
  	}
  }

  setPricingControls(countries: Country[], configuratorPriceTypes: ConfiguratorPrice[]): void {
  	this.generatePricingData(countries, configuratorPriceTypes);
  	this.pricesFormArray.updateValueAndValidity();
  }

  generatePricingData(countries: Country[], configuratorPriceTypes: ConfiguratorPrice[]): void {
  	const configurator: Configurator = this.productFacadeService.getSpecificState(ProductStateEnum.CONFIGURATOR)[0];
  	if (!countries.length) {
  		configuratorPriceTypes.forEach((configuratorPrice) => {
  			if (configuratorPrice.pricingType === 'normal') {
  				this.pushNormalPricesForm(configurator);
  			}
  		});
  	}

  	countries.forEach((country) => {
  		configuratorPriceTypes.forEach((configuratorPrice) => {
  			if (configuratorPrice.pricingType === 'normal') {
  				this.pushNormalPricesForm(configurator, country);
  			}
  		});
  	});
  }

  setDynamicColumnsFromProductTree(product: ProductTree): void {
  	product.package.dynamicColumns.forEach((dynamicColumn) => {
  		const formGroup = this.fb.group({
  			dynamicColumnId: dynamicColumn.id,
  			dynamicOptionId: [undefined, dynamicColumn.dataType === DataTypeEnum.Options && Validators.required],
  			id: undefined,
  			name: new MultiLanguages(),
  			valuableId: undefined,
  			value: undefined,
  			_destroy: undefined
  		});
  		this.dynamicValuesFormArray.push(formGroup);
  		this.packageDynamicValueForms.push(formGroup);
  	});
  	this.dynamicValuesFormArray.updateValueAndValidity();
  }

  buildDynamicValueFormGroup(): FormGroup {
  	return this.fb.group({
  		// eslint-disable-next-line @typescript-eslint/naming-convention
  		_destroy: undefined,
  		dynamicColumnId: undefined,
  		dynamicOptionId: undefined,
  		id: undefined,
  		name: this.buildLanguageFormGroup(),
  		valuableId: undefined,
  		value: undefined,
  	});
  }

  updatePrices(): void {
  	this.pricesFormArray.controls.forEach(priceForm =>
  		['mrc', 'otc'].forEach(key => priceForm.get(key).value && priceForm.get(key).setValue(+priceForm.get(key).value)));
  }

  createProductItem(): void {
  	this.updatePrices();
  	this.pricesFormArray.updateValueAndValidity();
  	this.dynamicValuesFormArray.updateValueAndValidity();
  	this.configuratorDynamicValueForms.forEach(form => form.markAllAsTouched());
  	SfFormUtil.markAllFormFieldsAsTouched(this.form);
  	SfFormUtil.focusOnErrorElement('p');
  	if (this.form.valid) {
  		this.dynamicValuesFormArray.controls.forEach((dynamicValueGroup) => {
  			if (dynamicValueGroup?.value?.dynamicOptionId) {
  				dynamicValueGroup.patchValue({ name: undefined });
  			}
  			if (dynamicValueGroup?.value?.name && !dynamicValueGroup?.value?.value) {
  				dynamicValueGroup.patchValue({ value: undefined });
  			}
  			if (dynamicValueGroup?.value?.value && typeof dynamicValueGroup?.value?.value !== 'string') {
  				dynamicValueGroup.patchValue({value: String(dynamicValueGroup?.value?.value)});
  			}
  		});
  		const productItem: ProductItemModel = this.form.value;
  		productItem.dynamicValues = this.productFacadeService.modifyDynamicValues(productItem.dynamicValues);
  		this.notifyDynamicLanguageError(productItem);
  		this.dynamicNamesExist && this.addProductItem(productItem);
  	}
  }

  notifyDynamicLanguageError(productItem: ProductItemModel): void {
  	this.dynamicNamesExist = productItem?.dynamicValues.every(dynamicValue =>
  		(dynamicValue?.name?.en || dynamicValue?.name?.de) ? (!!dynamicValue?.name?.en && !!dynamicValue?.name?.de) : true);
  	!this.dynamicNamesExist && this.notify.show('ERROR_DESCRIPTION_IN_BOTH_LANGUAGES', 'error');
  }

  addProductItem(productItem: ProductItemModel): void {
  	const obs$: Observable<unknown> = this.productItemIdRouteParam
  		? this.productFacadeService.updateProductItem(omit(productItem, 'packageId'))
  		: this.productFacadeService.createProductItem(productItem);
  	obs$.pipe(untilDestroyed(this)).subscribe(() => {
  		this.route.navigateByUrl(`/product-configurator/product/item-listing/${this.productTreeIdRouteParam}`);
  	});
  }

  cancel(): void {
  	this.route.navigateByUrl(`/product-configurator/product/item-listing/${this.productTreeIdRouteParam}`);
  }

  setLanguage = (language: string): void => {
  	this.selectedLanguageControl.setValue(language);
  };

  private pushNormalPricesForm(configurator: Configurator, country?: Country): void {
  	this.pricesFormArray.push(
  		this.fb.group({
  			id: undefined,
  			productItemId: undefined,
  			comparative: undefined,
  			quantity: undefined,
  			countryId: country?.id,
  			otc: [undefined,
  				(!country || country.default) && ['otc', 'mrc_otc'].includes(configurator?.chargeType) ? Validators.required : null,],
  			mrc: [undefined,
  				(!country || country.default) && ['mrc', 'mrc_otc'].includes(configurator?.chargeType) ? Validators.required : null,],
  		})
  	);
  }

  private buildLanguageFormGroup(formGroupValue: Record<string, string> = {}): FormGroup {
  	return this.fb.group(
  		Object.keys(new MultiLanguages()).reduce((fGroup, langKey) =>
  			({ ...fGroup, [langKey]: this.fb.control(formGroupValue[langKey], Validators.required) }), {}));
  }

  private updateImageFormValidators(): void {
  	if (!this.productFacadeService.getSpecificState<LevelModel>(ProductStateEnum.LEVEL).imageable) {
  		this.imagesFormArray.clearValidators();
  		this.imagesFormArray.updateValueAndValidity();
  	}
  }

  private pushDynamicColumnForm(dynamicColumn: DynamicColumn, columnIndex: number): void {
  	this.dynamicValuesFormArray.push(
  		this.formBuilder({
  			formModel: DynamicValueModel,
  			skipRequiredValidators: ['dynamicOptionId', 'id', 'name', 'valuableId', 'value', '_destroy'],
  		})
  	);
  	this.dynamicValuesFormArray.at(columnIndex).patchValue(this.productFacadeService.assignDynamicArrayValues(
  		dynamicColumn, columnIndex, this.dynamicValuesFormArray.controls[columnIndex] as FormGroup));
  }

  errorLangKeysForFormGroup(langGroupPath: string): string[] {
  	return this.languages.filter((lang) => {
  		const targetForm = this.getLangControlForFormPath(langGroupPath, lang);
  		return targetForm.invalid && targetForm.touched;
  	});
  }

  getLangControlForFormPath(langGroupPath: string, langControlKey: string): FormControl {
  	return this.form.get(langGroupPath).get(langControlKey) as FormControl;
  }

  get packageId(): string {
  	return this.activatedRoute.snapshot.paramMap.get('id');
  }

  get dynamicValuesFormArray(): FormArray {
  	return this.form.get('dynamicValues') as FormArray;
  }

  get specificationsFormArray(): FormArray {
  	return this.form.get('specifications') as FormArray;
  }

  get pricesFormArray(): FormArray {
  	return this.form.get('prices') as FormArray;
  }

  get imagesFormArray(): FormArray {
  	return this.form.get('images') as FormArray;
  }

  get imageIdsFormArray(): FormArray {
  	return this.form.get('imageIds') as FormArray;
  }

  get productTreeIdRouteParam(): string {
  	return this.activatedRoute.snapshot.paramMap.get('id');
  }

  get productItemIdRouteParam(): string {
  	return this.activatedRoute.snapshot.paramMap.get('item_id');
  }

  get allDynamicValueForms(): FormGroup[] {
  	return [...this.configuratorDynamicValueForms, ...this.packageDynamicValueForms];
  }
}
