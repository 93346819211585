import {Component} from '@angular/core';
import {DialogRef, SfDialogConfig} from '@safire/components';
import {ProductFacadeService} from '../../../services/product-facade.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {LevelModel, ProductTreeList} from '@ew/shared/services';

export interface NewDialogData {
    title?: string;
    message?: string;
    button?: string;
    productData?: ProductTreeList;
    multipleProductsData?: ProductTreeList[];
    actionType?: string;
    productType?: string;
    currentLevelState?: LevelModel;
    currentProductAncestry?: string;
}

@UntilDestroy()
@Component({
    selector: 'ew-product-confirmation-modal',
    templateUrl: './product-confirmation-modal.component.html',
    styleUrls: ['./product-confirmation-modal.component.scss']
})

export class ProductConfirmationModalComponent {
    data?: NewDialogData | null;

    constructor(private ref: DialogRef<string>, private config: SfDialogConfig<NewDialogData>,
                private productFacadeService: ProductFacadeService) {
    	this.data = config.data;
    }

    appendAction(): void {
    	this[`${this.data.actionType}${this.data.productType}`]();
    }

    changeStatusProduct(): void {
    	const productTreePayload = {...this.data.productData, active: !this.data.productData.active};
    	this.productFacadeService.updateProductActiveStatus(productTreePayload).pipe(untilDestroyed(this)).subscribe(() => {
    		this.getProductTrees();
    	});
    }

    deleteProduct(): void {
    	this.productFacadeService.deleteProductById(this.data?.productData?.id).subscribe(() => {
    		this.getProductTrees();
    	});
    }

    deleteMultipleProduct(): void {
    	this.data.multipleProductsData.forEach(product => {
    		this.productFacadeService.deleteProductById(product.id).subscribe(() => {
    			this.getProductTrees();
    		});
    	});
    }

    getProductTrees(): void {
    	this.productFacadeService.getProductTressByAncestryData(
    		this.data?.productData?.ancestry, this.data?.productData?.ancestryDepth);
    	this.ref.close(true);
    }

    cancel(): void {
    	this.ref.close(false);
    }
}
