import { Component, OnInit } from '@angular/core';
import { LevelFacadeService } from '../../services/level-facade.service';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SfDialogService } from '@safire/components';
import { Level, LevelModel, LevelTypeEnum } from '@ew/shared/services';
import { LevelConfiguratorModalComponent } from '../level-configurator-modal/level-configurator-modal.component';
import { FormGroup } from '@angular/forms';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';
import { AddonConfigurationModalComponent } from '../shared/components/addon-configuration-modal/addon-configuration-modal.component';

@UntilDestroy()
@Component({
    selector: 'ew-level-list',
    templateUrl: './level-list.component.html',
    styleUrls: ['./level-list.component.scss']
})
export class LevelListComponent extends FormBaseComponent implements OnInit {

  readonly levelType: typeof LevelTypeEnum = LevelTypeEnum;

  levels$: Observable<LevelModel[]>;
  contractDurationForm: FormGroup;

  constructor(private levelFacadeService: LevelFacadeService, private dialogService: SfDialogService) {
  	super();
  }

  ngOnInit(): void {
  	this.initialize();
  }

  initialize(): void {
  	this.getLevelPriceConfig();
  }

  setConfiguration(): void {
  	this.dialogService.open(LevelConfiguratorModalComponent, {
  		severity: 'primary'
  	})
  }

  getLevelPriceConfig(): void {
  	forkJoin([this.levelFacadeService.getConfigurators(), this.levelFacadeService.getCountries(),
  		this.levelFacadeService.getPriceTypes()]).pipe(untilDestroyed(this)).subscribe(() => {
  		this.getLevels();
  	});
  }

  createCategoryLevel(): void {
  	this.levelFacadeService.updateNewLevelState(true);
  }

  save(): void {
  	this.levelFacadeService.updateNewLevelState(false);
  	this.getLevels();
  }

  getLevels(): void {
  	this.levels$ = this.levelFacadeService.getLevels();
  }

  saveLevelState = (levelData: LevelModel): void => this.levelFacadeService.updateLevelState(levelData);

  checkLastLevel = (levelData: LevelModel[]): boolean => levelData.every(data => data.type === LevelTypeEnum.ProductNode);

  deleteLevel(levelData: LevelModel): void {
  	this.levelFacadeService.deleteLevelById(levelData.id).pipe(untilDestroyed(this)).subscribe(() => this.getLevels());
  }

  configureAddon(levels: Level[]): void {
  	this.dialogService.open(AddonConfigurationModalComponent, {
  		data: {
  			addonLevel: (levels || []).find(level => level.type === LevelTypeEnum.Addon)
  		}
  	}).afterClosed.subscribe((isSave) => isSave && this.getLevels());
  }

  get newLevel(): boolean {
  	return this.levelFacadeService.newLevel;
  }

}
