import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {SfFormsModule, SfIconModule, SfInputModule, SfTypographyModule} from '@safire/components';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PcSearchBarComponent} from './pc-search-bar/pc-search-bar.component';

@NgModule({
    imports: [CommonModule,
        SfFormsModule,
        FlexLayoutModule,
        SfInputModule,
        SfIconModule,
        SfTypographyModule,
        ReactiveFormsModule,
        TranslateModule],
    declarations: [
        SearchBarComponent,
        PcSearchBarComponent
    ],
    exports: [
        SearchBarComponent,
        PcSearchBarComponent
    ]
})
export class SharedComponentsSearchBarModule {
}
