import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabularViewComponent } from './tabular-view/tabular-view.component';
import {
    SfCheckboxModule, SfChipsModule,
    SfDataTableModule,
    SfFormsModule, SfIconButtonModule,
    SfIconModule,
    SfInputModule, SfPaginationModule,
    SfSelectModule, SfSwitchModule
} from '@safire/components';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SharedComponentsMenuModalModule } from '@ew/shared/components/menu-modal';
import { SharedPipeTranslateNameModule } from '@ew/shared/pipe/translate-name';

@NgModule({
    imports: [
        CommonModule,
        SfDataTableModule,
        SfIconModule,
        SfCheckboxModule,
        SfFormsModule,
        SfInputModule,
        SfSelectModule,
        FlexLayoutModule,
        SfSwitchModule,
        SfChipsModule,
        RouterModule,
        SfPaginationModule,
        TranslateModule,
        FormsModule,
        SharedComponentsMenuModalModule,
        SharedPipeTranslateNameModule,
        SfIconButtonModule
    ],
    declarations: [
        TabularViewComponent
    ],
    exports: [TabularViewComponent]
})
export class SharedComponentsTabularViewModule {}
