import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {SfFormUtil} from '@safire/angular-utils';
import {DialogRef, SfDialogConfig, SfSnackBarService} from '@safire/components';
import {
    DataTypeEnum,
    DynamicColumn,
    DynamicValueModel,
    Image,
    ImagesAttributes,
    MultiLanguages,
    ProductTreeFormDialogData,
    UpdatePackage,
} from '@ew/shared/services';
import {keys} from 'lodash-es';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {ProductFacadeService} from '../../../services/product-facade.service';
import {ProductBaseComponent} from '../../product-base/product-base.component';

@Component({
    selector: 'ew-service-create-modal',
    templateUrl: './service-create-modal.component.html',
    styleUrls: ['./service-create-modal.component.scss'],
})
export class ServiceCreateModalComponent extends ProductBaseComponent implements OnInit {
    languages: string[] = keys(new MultiLanguages());
    productInfo: ProductTreeFormDialogData | null;

    isEdit = false;

    previewImageUrls: string[] = [];

    selectedLanguageControl: FormControl = new FormControl();

    constructor(
        private ref: DialogRef<ServiceCreateModalComponent>,
        private productFacadeService: ProductFacadeService,
        @Optional() @Inject(SfDialogConfig) public config: { data: ProductTreeFormDialogData },
        private notify: SfSnackBarService,
        public translate: TranslateService
    ) {
    	super();
    	this.form.addControl('dynamicValues', new FormArray([]));
    	this.productInfo = this.config.data;
    }

    get name(): FormGroup {
    	return this.form.get('name') as FormGroup;
    }

    get dynamicValuesFormArray(): FormArray {
    	return this.form.get('dynamicValues') as FormArray;
    }

    get images(): FormArray {
    	return this.form.get('images') as FormArray;
    }

    get imageIds(): FormArray {
    	return this.form.get('imageIds') as FormArray;
    }

    ngOnInit(): void {
    	this.isEdit = this.productInfo.actionType !== 'create';
    	this.setDynamicValueControls();
    	if (this.isEdit) {
    		this.patchDataToForm();
    	}
    	this.selectedLanguageControl.setValue(this.translate.currentLang);
    }

    patchDataToForm(): void {
    	this.patchImages();

    	this.productFacadeService.getProductTreeById(this.productInfo.productData.id, false).subscribe((tree) => {
    		this.form.patchValue({
    			...tree.package,
    		});
    	});
    }

    patchImages(): void {
    	this.productInfo?.productData?.images?.forEach((image) => {
    		this.images.push(
    			this.formBuilder({
    				formModel: function () {
    					return {
    						id: image.id,
    						// eslint-disable-next-line @typescript-eslint/naming-convention
    						_destroy: undefined,
    					};
    				},
    				skipRequiredValidators: ['_destroy'],
    			})
    		);
    		this.imageIds.push(new FormControl(image.id));
    		this.previewImageUrls.push(image.urls[0]);
    	});
    }

    copyContent(): void {
    	this.productFacadeService.copyContent(this.form, 'productDetail', this.selectedLanguageControl.value);
    	this.productInfo.currentLevelState.dynamicColumns?.forEach((dynamicColumn, i) => {
    		const formGroup = this.dynamicValuesFormArray.at(i).get('name') as FormGroup;
    		if (dynamicColumn.multilingual && [DataTypeEnum.String, DataTypeEnum.Text].includes(dynamicColumn.dataType)) {
    			Object.keys(new MultiLanguages()).forEach((controlKey) =>
    				formGroup.get(controlKey).setValue(formGroup.get(this.selectedLanguageControl.value).value)
    			);
    		}
    	});
    	this.notify.open(this.translate.instant('HINT_COPY_NAME_DESCRIPTION'), undefined, 'success', {
    		duration: 1000,
    		verticalPosition: 'top',
    		horizontalPosition: 'center',
    	});
    }

    uploadImage(file: File): Observable<Image> {
    	return this.productFacadeService.uploadImage(file).pipe(
    		tap((response) => {
    			this.images.push(this.buildImageFormGroup({id: response.id}));
    			this.imageIds.push(new FormControl(response.id));
    		})
    	);
    }

    buildImageFormGroup(image: ImagesAttributes): FormGroup {
    	return this.formBuilder({
    		formModel: function () {
    			// eslint-disable-next-line @typescript-eslint/naming-convention
    			return {id: null, _destroy: undefined, ...image};
    		},
    		skipRequiredValidators: ['_destroy'],
    	});
    }

    removeImageAtIndex(index: number): void {
    	if (!this.isEdit) {
    		this.images.removeAt(index);
    		this.imageIds.removeAt(index);
    	} else {
    		// eslint-disable-next-line @typescript-eslint/naming-convention
    		this.images.at(index).patchValue({_destroy: true});
    	}
    }

    updateService(): void {
    	const payload: UpdatePackage = this.productFacadeService.modifyPatchFormData(this.productInfo.productData, this.form.value);
    	payload.dynamicValues = this.productFacadeService.modifyDynamicValues(payload.dynamicValues);
    	this.triedSubmit = true;
    	SfFormUtil.markAllFormFieldsAsTouched(this.form);
    	SfFormUtil.focusOnErrorElement('mat-error');
    	this.form.valid && this.productFacadeService.updatePackage(payload)
    		.pipe(untilDestroyed(this)).subscribe(() => {
    			this.productFacadeService.getProductTressByAncestryData(
    				this.productInfo.currentProductAncestry,
    				this.productInfo.currentLevelState.rank
    			);this.ref.close(true);
    		});
    }

    createService(): void {
    	this.triedSubmit = true;
    	SfFormUtil.markAllFormFieldsAsTouched(this.form);
    	SfFormUtil.focusOnErrorElement('mat-error');
    	this.form.valid && this.productFacadeService
    		.generatePackagePayload(this.form.value, this.productInfo.currentLevelState, this.productInfo.currentProductId)
    		.subscribe(() => {
    			this.productFacadeService.getProductTressByAncestryData(
    				this.productInfo.currentProductAncestry,
    				this.productInfo.currentLevelState.rank
    			);
    			this.ref.close(true);
    		});
    }

    errorLanguages(type: string): string[] {
    	return this.languages.filter((lang) => this.getControl(type, lang)?.invalid && this.getControl(type, lang)?.touched);
    }

    dynamicValueLanguageErrors(columnIndex: number): string[] {
    	return this.languages.filter((lang) => {
    		const control = (this.form.get('dynamicValues') as FormArray).at(columnIndex)?.get('name')?.get(lang);
    		return control?.touched && control?.invalid;
    	});
    }

    setLanguage = (language: string): void => {
    	this.selectedLanguageControl.setValue(language);
    };

    getControl = (type: string, lang: string): FormControl => this.form.get(type + '.' + lang) as FormControl;

    cancel(): void {
    	this.ref.close(false);
    }

    private setDynamicValueControls(): void {
    	this.config.data.currentLevelState.dynamicColumns.forEach(this.pushDynamicColumnForm.bind(this));
    }

    private pushDynamicColumnForm(dynamicColumn: DynamicColumn, columnIndex: number): void {
    	this.dynamicValuesFormArray.push(
    		this.formBuilder({
    			formModel: DynamicValueModel,
    			skipRequiredValidators: ['dynamicOptionId', 'id', 'name', 'valuableId', 'value', '_destroy'],
    		})
    	);
    	this.dynamicValuesFormArray.at(columnIndex).patchValue({
    		dynamicColumnId: dynamicColumn.id,
    	});
    	this.setDynamicValuesValidators(dynamicColumn, columnIndex);
    }

    private setDynamicValuesValidators(dynamicColumn: DynamicColumn, columnIndex: number): void {
    	if (dynamicColumn.required) {
    		if (dynamicColumn.multilingual) {
    			this.dynamicValuesFormArray.at(columnIndex).get('name').setValidators(Validators.required);
    			Object.keys(new MultiLanguages()).forEach((langKey) => {
    				this.dynamicValuesFormArray.at(columnIndex).get('name').get(langKey).setValidators(Validators.required);
    				this.dynamicValuesFormArray.at(columnIndex).get('name').get(langKey).updateValueAndValidity();
    			});
    		} else {
    			this.dynamicValuesFormArray.at(columnIndex).get('value').setValidators(Validators.required);
    			this.dynamicValuesFormArray.at(columnIndex).get('value').updateValueAndValidity();
    		}
    		if (dynamicColumn.dataType === DataTypeEnum.Options) {
    			this.dynamicValuesFormArray.at(columnIndex).get('dynamicOptionId').setValidators(Validators.required);
    			this.dynamicValuesFormArray.at(columnIndex).get('dynamicOptionId').updateValueAndValidity();
    		}
    	}
    	this.dynamicValuesFormArray.at(columnIndex).updateValueAndValidity();
    }
}
