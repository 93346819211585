import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, SfDialogConfig } from '@safire/components';
import {
    LevelModel,
    ProductFormBuilder,
    ProductStateEnum,
    ProductTreeList,
    ProductTreesQueryModel,
    TableDataSource,
} from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ProductFacadeService } from '../../../services/product-facade.service';

export interface NewDialogData {
  currentLevel?: LevelModel;
  currentProductId?: string;
  currentProductAncestry?: string;
  currentProduct?: ProductTreeList;
}

@UntilDestroy()
@Component({
    selector: 'ew-product-node-list-modal',
    templateUrl: './product-node-list-modal.component.html',
    styleUrls: ['./product-node-list-modal.component.scss'],
})
export class ProductNodeListModalComponent extends FormBaseComponent implements OnInit {
  productTreesConnection$: Observable<TableDataSource<ProductTreeList>>;

  packageId: string;
  packageName: string;
  isDisable: boolean;

  constructor(
    private ref: DialogRef<ProductNodeListModalComponent>,
    private productFacadeService: ProductFacadeService,
    public translate: TranslateService,
    @Optional() @Inject(SfDialogConfig) public config: { data: NewDialogData }
  ) {
  	super();
  	this.configureForm(ProductFormBuilder.productTreesQueryFormSchema());
  }

  ngOnInit(): void {
  	this.productTreesConnection$ = this.form.get('query').valueChanges.pipe(
  		switchMap((query) => this.fetchProductTrees(query)),
  		map((connection) => this.mapProductTreesToConnection(connection))
  	);
  }

  selectProduct(packageId: string, packageName: string): void {
  	this.packageId = packageId;
  	this.packageName = packageName;
  	this.isDisable = true;
  }

  addProductToExistingProductTrees(): void {
  	this.productFacadeService
  		.mapPackageToProductTrees(
  			this.config?.data?.currentProduct.id,
  			this.packageId,
  			this.packageName,
  			this.config?.data?.currentLevel?.name[this.translate.currentLang]
  		)
  		.subscribe(() => {
  			this.productFacadeService.getProductTressByAncestryData(
  				this.productFacadeService.getSpecificState<ProductTreesQueryModel>(ProductStateEnum.PRODUCT_TREES_QUERY)?.ancestry,
  				this.config?.data?.currentLevel?.rank
  			);
  		});
  	this.ref.close(true);
  }

  cancel(): void {
  	this.ref.close(false);
  }

  get queryFormControl(): FormControl {
  	return this.form.get('query') as FormControl;
  }

  private mapProductTreesToConnection(connection: TableDataSource<ProductTreeList>): TableDataSource<ProductTreeList> {
  	const currentAncestry = this.productFacadeService.getSpecificState<ProductTreesQueryModel>(ProductStateEnum.PRODUCT_TREES_QUERY)
  		?.ancestry;
  	return {
  		...connection,
  		collection: connection?.collection.filter((productTree) => productTree.ancestry !== currentAncestry),
  	};
  }

  private fetchProductTrees(query: string): Observable<TableDataSource<ProductTreeList>> {
  	return this.productFacadeService.getProductTrees(
  		{
  			query: query,
  			uniqueProductTrees: this.config?.data?.currentLevel?.rank || 0,
  		},
  		false
  	);
  }
}
