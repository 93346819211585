import { Injectable } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    AddPackage, DataTypeEnum, DynamicColumn, DynamicValue, DynamicValueModel, DynamicValuesAttributes,
    EventData, Level, LevelModel, LevelRuleModel, LevelTypeEnum, MultiLanguages, Package,
    PackageModel, ProductsStoreState, ProductTree, ProductTreeList, TabConfig, TableDataSource, UpdatePackage
} from '@ew/shared/services';
import { keys, merge } from 'lodash-es';
import { SfSnackBarService } from '@safire/components';
import { CustomDynamicCheckBoxValue, ModifiedProductTreeListWithRegions, ModifiedProductTreeWithRegions } from '../models/package.model';
import { DynamicCheckboxType } from '@ew/shared/components/dynamic-field';

@Injectable({
    providedIn: 'root',
})
export class ProductBlService {
  levelPayload: Level;
  tab: TabConfig[] = [];

  constructor(private translateService: TranslateService, private notify: SfSnackBarService) {}

  modifyPatchFormData = (productInfo: ProductTreeList, packageValue: Package): UpdatePackage => ({
  	id: productInfo.packageId,
  	name: packageValue.name,
  	images: packageValue.images.map((image) => ({
  		id: image.id,
  		// eslint-disable-next-line @typescript-eslint/naming-convention
  		...(image['_destroy'] ? { _destroy: true } : {}),
  	})),
  	imageIds: packageValue.imageIds,
  	...(productInfo.packageConfiguratorPriceTypeId
  		? {
  			configuratorPriceTypeId: productInfo.packageConfiguratorPriceTypeId,
  		}
  		: {}),
  	dynamicValues: packageValue.dynamicValues,
  });

  configureMultiLanguage(name: MultiLanguages): string {
  	return name[this.translateService.currentLang];
  }

  configureLevelRule(rule: LevelModel[]): LevelRuleModel {
  	rule[0].levelRule.id = rule[0].id;
  	return rule[0].levelRule as LevelRuleModel;
  }

  copyContent(form: FormGroup, copyType: string, selectedLangKey: string): void {
  	if (copyType === 'optionSet') this.copyContentForOptionSet(form, selectedLangKey);
  	if (copyType === 'option') this.copyContentForOption(form), selectedLangKey;
  	if (copyType === 'productDetail') this.copyContentForProductDetail(form, selectedLangKey);
  }

  copyContentForOption(form: FormGroup): void {
  	const data = form.value;
  	const content: { [keys: string]: string } = {
  		name: data.dynamicColumnsAttributes[0][data?.selected_language],
  	};
  	keys(new MultiLanguages()).forEach((lang) => {
  		data.name[lang] = content.name;
  	});
  	form.patchValue(data);
  }

  copyContentForOptionSet(form: FormGroup, selectedLangKey: string): void {
  	if (form.value['dynamicColumns'].length) {
  		const data = form.value;
  		data.dynamicColumns.forEach((value, index) => {
  			keys(new MultiLanguages()).forEach((lang) => {
  				value.name[lang] = (form.get('dynamicColumns') as FormArray).at(index).get('name').value[selectedLangKey];
  			});
  			value.dynamicOptions.forEach((opt, optIndex) => {
  				keys(new MultiLanguages()).forEach((lang) => {
  					opt.name[lang] = ((form.get('dynamicColumns') as FormArray).at(index).get('dynamicOptions') as FormArray)
  						.at(optIndex)
  						.get('name').value[selectedLangKey];
  				});
  			});
  		});
  		form.patchValue(data);
  	}
  }

  copyContentForProductDetail(form: FormGroup, selectedLangKey: string): void {
  	const data: Package = form.value;
  	const content: { [keys: string]: string } = {
  		name: data?.name[selectedLangKey],
  	};
  	keys(new MultiLanguages()).forEach((lang) => {
  		data.name[lang] = content.name;
  		data?.dynamicValues?.forEach((dynamicValue) => {
  			if (dynamicValue.name[selectedLangKey]) {
  				dynamicValue.name[lang] = dynamicValue.name[selectedLangKey];
  			}
  		});
  	});
  	form.patchValue(data);
  }

  setPackageAttributes(stateData, event: { value: Package }): Package {
  	stateData = {
  		packageState: merge(stateData?.packageState, event.value),
  	};
  	return stateData.packageState;
  }

  mapToAddPackagePayload(packageValue: Package, level: LevelModel, parentPackageId: string): AddPackage {
  	const type = {
  		[LevelTypeEnum.Product]: 'Pc::Core::Product',
  		[LevelTypeEnum.ProductNode]: 'Pc::Core::ProductNode',
  		[LevelTypeEnum.Addon]: 'Pc::Core::Addon',
  	}[level.type];
  	return {
  		package: {
  			name: packageValue.name,
  			images: packageValue.images,
  			imageIds: packageValue.imageIds,
  			type,
  			levelId: level.id,
  			dynamicValues: packageValue.dynamicValues,
  		},
  		parentId: parentPackageId,
  	};
  }

  checkParentId(level: LevelModel, packageId: string): string {
  	return level['data'].attributes.rank === 0 ? null : packageId;
  }

  updateAllProductState(stateData: ProductsStoreState, event: EventData): { productState: ProductTreeList } {
  	return {
  		productState: { ...stateData?.productTree, ...event.value } as ProductTreeList,
  	};
  }

  // eslint-disable-next-line max-lines-per-function
  createProduct(productState: PackageModel, level: Level, packageId: string, configuratorPriceTypeId: string, isAddon = false): AddPackage {
  	delete productState.id;
  	return {
  		parentId: packageId,
  		package: {
  			levelId: level?.id,
  			configuratorPriceTypeId: configuratorPriceTypeId,
  			type: isAddon ? 'Pc::Core::Addon' : 'Pc::Core::Product',
  			name: productState.name,
  			meta: {},
  			imageIds: productState.imageIds,
  			...(productState.preorder?.startDate && productState.preorder?.releaseDate ? { preorder: productState.preorder } : {}),
  			...(productState.packageContractDurations?.length ? { packageContractDurations: productState.packageContractDurations } : {}),
  			deliveryTime: productState.deliveryTime,
  			images: productState.images,
  			dynamicColumns: productState.dynamicColumns,
  			dynamicValues: this.modifyDynamicValues(productState.dynamicValues),
  		},
  		meta: {},
  	};
  }

  modifyDynamicValues(dynamicValues: DynamicValue[]): DynamicValuesAttributes[] {
  	let dynamicValueDetails: DynamicValuesAttributes[] = [];
  	dynamicValues.forEach(dynamicValue => {
  		if (dynamicValue?.id) {
  			dynamicValueDetails.push((dynamicValue?.dynamicOptionId || dynamicValue?.value || dynamicValue?.name?.en) ?
  				dynamicValue : {...dynamicValue, _destroy: true});} 
          if (this.isValueTypeAnArray(dynamicValue)) {
			  //Values comes from the Dynamic Column Form after Submitting
              (dynamicValue.dynamicOptionId as unknown as []).forEach((checkBoxValue: DynamicCheckboxType) => {
			  if (!!checkBoxValue?.id && !checkBoxValue?.selected) {
				  //When checkbox state is from selected to unselect 
                      dynamicValueDetails.push({...dynamicValue, 
                          dynamicOptionId: checkBoxValue?.optionId, _destroy: true, id: checkBoxValue?.id});
			   } else if (!checkBoxValue?.id && checkBoxValue?.selected) {
					  //When checkbox state is from unselected to selected (delete id field)
                      const value = {...dynamicValue, dynamicOptionId: checkBoxValue?.optionId};
                      delete value?.id;
                      dynamicValueDetails.push(value);
			   }
              });
    	} else {(dynamicValue?.value || dynamicValue?.name.en || dynamicValue?.dynamicOptionId) && dynamicValueDetails.push(dynamicValue);}
  	});
      dynamicValueDetails = this.removeDuplicatesFromArrayOfObjects(dynamicValueDetails.filter(dynamicValue => 
          !(this.isValueTypeAnArray(dynamicValue))));
  	return dynamicValueDetails;
  }

  private isValueTypeAnArray = (dyanmicValuesAttr: DynamicValuesAttributes | DynamicValue): boolean  => {
      return !!dyanmicValuesAttr.dynamicOptionId && (typeof dyanmicValuesAttr?.dynamicOptionId === 'object');
  }
  
  //TODO: Need to move this to Shared.ts File (Zangphel)
  removeDuplicatesFromArrayOfObjects(dynamicColumnValues: DynamicValuesAttributes[]): DynamicValuesAttributes[] {
      const duplicateRemovedDynamicColumns = [...new Set(dynamicColumnValues.map(dynamicColumn => JSON.stringify(dynamicColumn)))];
      const finalFilteredDynalicColumns = duplicateRemovedDynamicColumns.map(dynamicColumn => JSON.parse(dynamicColumn))
      return finalFilteredDynalicColumns;
  }

  assignDynamicArrayValues(dynamicColumn: DynamicColumn, columnIndex: number, dynamicValuesForm: FormGroup): DynamicValueModel {
  	dynamicValuesForm.patchValue({dynamicColumnId: dynamicColumn.id});
  	if (dynamicColumn.required) {
  		if (dynamicColumn.multilingual) {
  			dynamicValuesForm.get('name').setValidators(Validators.required);
  			dynamicValuesForm.updateValueAndValidity();
  			Object.keys(new MultiLanguages()).forEach((langKey) => {
  				dynamicValuesForm.get('name').get(langKey).setValidators(Validators.required);
  				dynamicValuesForm.get('name').get(langKey).updateValueAndValidity();
  			});
  		} else {
  			dynamicValuesForm.get('value').setValidators(Validators.required);
  			dynamicValuesForm.get('value').updateValueAndValidity();
  		}
  		if (dynamicColumn.dataType === DataTypeEnum.Options) {
  			dynamicValuesForm.get('dynamicOptionId').setValidators(Validators.required);
  			dynamicValuesForm.get('dynamicOptionId').updateValueAndValidity();
  		}
  	}
  	return dynamicValuesForm.value;
  }

  openErrorNotification(message: string): void {
  	this.notify.open(this.translateService.instant(message),
  		undefined, 'error', {duration: 2000,
  			verticalPosition: 'top',
  			horizontalPosition: 'center',
  		});
  }

  mapProductTreeListWithRegions(productTreeList: 
	TableDataSource<ProductTreeList>, level: Level): TableDataSource<ModifiedProductTreeListWithRegions> {
	  const dynamicCheckBoxColumn = level.dynamicColumns.find(dynamicColumn => dynamicColumn.dataType === DataTypeEnum.Checkbox);
	  //get dyanmic checkbox option ids and names
      const dynamicCheckBoxOptionIds = dynamicCheckBoxColumn?.dynamicOptions?.map(dynamicOption => 
      {return {name: dynamicOption.value, id: dynamicOption?.id}});
	  //get dynamicCheckboxColumnId
      const dynamicCheckBoxColumnId = dynamicCheckBoxColumn?.id;
      return {...productTreeList, collection: productTreeList.collection.map(productPackageList => {return {
          ...productPackageList,
          regions: this.getCheckboxValueBasedOnNumberOfSelectedCheckBox(productPackageList, 
			  dynamicCheckBoxColumnId, dynamicCheckBoxOptionIds)
      }})};
  }

  getCheckboxValueBasedOnNumberOfSelectedCheckBox(productTreeList: ProductTreeList, 
      dynamicCheckBoxColumnId: string, dynamicCheckBoxOptionIds: CustomDynamicCheckBoxValue[]): string {
      //get checkbox value from individual package
      const dynamicCheckBoxValue = 
	  	productTreeList?.dynamicValues?.filter(dynamicValue => dynamicValue.dynamic_column_id === dynamicCheckBoxColumnId);
	  return (dynamicCheckBoxValue?.length >= 3) ? this.translateService.instant('LABEL_ALL_REGION') 
	  	: dynamicCheckBoxOptionIds?.filter(checkboxOptionID => dynamicCheckBoxValue?.map(checkboxVal => 
		  checkboxVal.dynamic_option_id).includes(checkboxOptionID.id))?.map(checkboxVal => checkboxVal?.name).join(' & ') ||
		   this.translateService.instant('HINT_REGION_NOT_SPECIFIED');
  }
  
  mapProductPackageWithRegion(productPackage: ProductTree, label: LevelModel): ModifiedProductTreeWithRegions {
      const dynamicCheckBoxColumn = label.dynamicColumns.find(dynamicColumn => dynamicColumn.dataType === DataTypeEnum.Checkbox);
      const mapRegionNameAndIdFromCheckboxColumn = dynamicCheckBoxColumn.dynamicOptions.map(dynamicOption => 
      {return {name: dynamicOption.value, id: dynamicOption?.id}});
      const filterCheckboxValues = productPackage.package.dynamicValues.filter(dynamicValue => 
          dynamicValue.dynamicColumnId === dynamicCheckBoxColumn.id);
      const modifiedProductTree = {
          ...productPackage, regions: (filterCheckboxValues?.length >= 3) ? this.translateService.instant('LABEL_ALL_REGION') : 
              mapRegionNameAndIdFromCheckboxColumn.filter(dyanmicCheckboxColumn => 
                  filterCheckboxValues.map(filterCheckboxValue=> filterCheckboxValue.dynamicOptionId).
                      includes(dyanmicCheckboxColumn.id)).map(checkboxValue => checkboxValue.name).join(' & ') 
				  || this.translateService.instant('HINT_REGION_NOT_SPECIFIED')
      }
      return modifiedProductTree;
  }
}
