import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModalComponent } from './menu-modal/menu-modal.component';
import { SfIconModule, SfMenuModule, SfListModule } from '@safire/components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, SfIconModule, TranslateModule, SfMenuModule, SfListModule],
    declarations: [
        MenuModalComponent
    ],
    exports: [MenuModalComponent]
})
export class SharedComponentsMenuModalModule {}
