import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SfFormUtil } from '@safire/angular-utils';
import { DialogRef } from '@safire/components';
import {
    Configurator,
    ControlOptions,
    Country,
    MultiLanguages,
    LevelFormBuilder,
    LevelsStoreState
} from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';
import { keys } from 'lodash-es';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { LevelFacadeService } from '../../services/level-facade.service';

@UntilDestroy()
@Component({
    selector: 'ew-level-configurator-modal',
    templateUrl: './level-configurator-modal.component.html',
    styleUrls: ['./level-configurator-modal.component.scss'],
})
export class LevelConfiguratorModalComponent
    extends FormBaseComponent
    implements OnInit {
  levelState$: Observable<LevelsStoreState>;
  configData: Configurator;
  filteredCountries: Country[] = [];
  languages: string[] = keys(new MultiLanguages());
  optionControls: ControlOptions[] = [
  	{
  		label: 'Input Name',
  		key: 'name',
  		type: 'input',
  	},
  ];

  countrySearchControl: FormControl = new FormControl();

  constructor(
    private ref: DialogRef<string>,
    private levelFacadeService: LevelFacadeService,
    private fb: FormBuilder
  ) {
  	super();
  	this.configureForm(LevelFormBuilder.configFormInitializer());
  	this.levelState$ = this.levelFacadeService.stateChange().pipe(untilDestroyed(this));
  }

  ngOnInit(): void {
  	this.initialize();
  }

  initialize(): void {
  	this.valueChange();
  	this.configData = this.levelFacadeService.levelConfig;
  	this.patchValues();
  }

  valueChange(): void {
  	this.countrySearchControl.valueChanges
  		.pipe(
  			debounceTime(200),
  			untilDestroyed(this)
  		)
  		.subscribe((value) => {
  			const countriesList: Country[] =
          this.levelFacadeService.countryState || [];
  			this.filteredCountries = countriesList?.filter((country: Country) =>
  				country.name?.toLowerCase().includes(value?.toLowerCase())
  			);
  		});
  }

  patchValues(): void {
  	const levelConfigurator: Configurator = this.configData;
  	this.form.patchValue({
  		...levelConfigurator,
  	});
  	this.getArrayOfControls(levelConfigurator);
  }

  save(): void {
  	this.updateDynamicOptionsForm();
  	SfFormUtil.markAllFormFieldsAsTouched(this.form);
  	SfFormUtil.focusOnErrorElement('mat-error');
  	if (this.form.valid) {
  		this.levelFacadeService
  			.updateLevelConfig(this.form.value)
  			.pipe(
  				switchMap(() => this.levelFacadeService.getConfigurators()),
  				untilDestroyed(this)
  			)
  			.subscribe(() => this.ref.close(true));
  	}
  }

  cancel(): void {
  	this.ref.close(false);
  }

  getArrayOfControls(included: Configurator): void {
  	this.levelFacadeService.patchConfigControls(
  		included,
  		'configuratorPriceTypes',
  		this.priceAttribute,
  		this.fb
  	);
  	this.levelFacadeService.patchConfigControls(
  		included,
  		'countries',
      this.form.get('countries') as FormArray,
      this.fb
  	);
  	this.levelFacadeService.patchConfigControls(
  		included,
  		'dynamicColumns',
  		this.dynamicAttribute,
  		this.fb
  	);
  	this.patchOptionControls(included);
  }

  patchOptionControls(configurator: Configurator): void {
  	configurator.dynamicColumns.forEach((dynamicColumn) => {
  		const columnIndex: number = this.dynamicAttribute.controls.findIndex(
  			(ctrl) => ctrl.value.id === dynamicColumn.id
  		);
  		dynamicColumn.dynamicOptions.forEach(option => {
  			this.dynamicOptionsAttribute(columnIndex)?.push(
  				this.levelFacadeService.dynamicFormData(
  					option,
  					'dynamicOptionInitializer'
  				)
  			);
  		});

  		this.dynamicOptionsAttribute(columnIndex)?.updateValueAndValidity();
  	});
  }

  updateDynamicOptionsForm(): void {
  	this.dynamicAttribute.controls?.forEach((control) =>
  		control?.get('dynamicOptions')?.updateValueAndValidity()
  	);
  	this.dynamicAttribute.updateValueAndValidity();
  }

  get priceAttribute(): FormArray {
  	return this.form.get('configuratorPriceTypes') as FormArray;
  }

  get dynamicAttribute(): FormArray {
  	return this.form.get('dynamicColumns') as FormArray;
  }

  dynamicOptionsAttribute(index: number): FormArray {
  	return this.dynamicAttribute.at(index)?.get(
  		'dynamicOptions'
  	) as FormArray;
  }
}
