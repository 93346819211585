import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuConfig, TableConfig, TableDataSource } from '@ew/shared/services';
import { SelectionModel } from '@angular/cdk/collections';
import { PageEvent, SfTableRowComponent } from '@safire/components';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'ew-tabular-view',
    templateUrl: './tabular-view.component.html',
    styleUrls: ['./tabular-view.component.scss']
})
export class TabularViewComponent implements OnInit {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection: SelectionModel<TableDataSource<any>>;
  hasFilterValue: boolean;
  displayedColumns: string[];
  get: unknown = get;
  pageEvent: PageEvent;

  @Input() menuConfig: MenuConfig[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() dataSource: TableDataSource<any>;
  @Input() showAllData: boolean;
  @Input() tableConfig: TableConfig[];
  @Input() reset: Observable<number>;
  @Input() showResetFilter: boolean;
  @Input() loading: boolean;
  @Input() noDataLabel: string;
  @Input() hasMultiple: boolean;
  @Input() customRoute: string;
  @Input() set setPageEvent(pageIndex: number) {
  	this.pageEvent = { pageIndex: pageIndex || 0, pageSize: 10 } as PageEvent;
  }

  @Output() action: EventEmitter<{ actionKey: string, row: unknown }> = new EventEmitter<{ actionKey: string, row: unknown }>();
  @Output() showDetails: unknown = new EventEmitter<unknown>();
  @Output() selectedRows: EventEmitter<TableDataSource<unknown>[]> = new EventEmitter<TableDataSource<unknown>[]>();
  @Output() updateSearchParam: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  	this.showResetFilter = false;
  	this.hasMultiple = true;
  }

  ngOnInit(): void {
  	this.setPageEvent = 0;
  	this.selection = new SelectionModel(this.hasMultiple, []);
  	this.displayedColumns = [...(this.hasMultiple ? ['select'] : []), ...this.tableConfig.map(config => config.name)];
  }

  isAllSelected(): boolean {
  	const numSelected: number = this.selection.selected.length;
  	const numRows: number = this.dataSource.collection.length;
  	return numSelected === numRows;
  }

  masterToggle(): void {
  	this.isAllSelected() ? this.selection.clear() :
  		this.dataSource.collection.forEach(row => this.selection.select(row));
  	this.selectedRows.emit(this.selection.selected);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleSingle(row: any): void {
  	this.selection.toggle(row);
  	this.selectedRows.emit(this.selection.selected);
  }

  pageChange(pageEvent: PageEvent): void {
  	this.pageEvent = pageEvent;
  	this.pageEvent.length && this.updateSearchParam.emit(this.searchParams);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionEvent(actionType: string, element: any, $event?: Event): void {
  	this.action.emit({ actionKey: actionType, row: element });
  	$event && $event.stopPropagation();
  }

  loadData(): void {
  	this.renderResetBtn();
  	this.setPageEvent = 0;
  	this.updateSearchParam.emit({ ...this.searchParams });
  }

  renderResetBtn(): void {
  	this.hasFilterValue = !!this.tableConfig.find(config => !!config.filterValue);
  }

  clearFilter(selectedConfig?: TableConfig): void {
  	this.tableConfig.forEach(config => (selectedConfig?.key === config.key || !selectedConfig) && (config.filterValue = undefined));
  	this.renderResetBtn();
  	this.setPageEvent = 0;
  	this.updateSearchParam.emit({ ...this.searchParams, query: undefined });
  }

  route(element: SfTableRowComponent): void {
  	this.customRoute && this.router.navigateByUrl(this.customRoute + element.id);
      //TODO: Might Need in the future (27-02-2024)
      // this.router.navigate([element.id], { relativeTo: this.activatedRoute }).catch(() => {return null});
  }

  get advanceFilter(): Record<string, unknown> {
  	const params = {};
  	this.tableConfig.forEach(config => config.filterName && (params[config.filterName] = config.filterValue || undefined));
  	return params;
  }

  get searchParams(): Record<string, unknown> {
  	return {
  		...this.advanceFilter,
  		page: this.pageEvent.pageIndex + 1,
  		perPage: this.pageEvent.pageSize
  	};
  }
}
