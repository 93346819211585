import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PageEvent, SfTabBarComponent } from '@safire/components';
import {
    ItemsQueryModel, LevelModel, LevelTypeEnum,
    MultiLanguages, ProductFormBuilder, ProductItem,
    ProductItemsList, ProductsStoreState, ProductStateEnum,
    ProductTree, ProductTreeList, ProductTreesQueryModel, TableDataSource,
} from '@ew/shared/services';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { PRODUCT_ITEM_MENU_CONFIG, PRODUCT_ITEMS_TABLE_CONFIG } from '../../../constants/products.constant';
import { ProductFacadeService } from '../../../services/product-facade.service';
import {FormBaseComponent, ReactiveFormSchema} from '@ew/shared/utils/form-utils';
import { ModifiedProductTreeWithRegions } from '../../../models/package.model';

@UntilDestroy()
@Component({
    selector: 'ew-item-listing',
    templateUrl: './item-listing.component.html',
    styleUrls: ['./item-listing.component.scss'],
})
export class ItemListingComponent extends FormBaseComponent implements OnInit {
  readonly levelType: typeof LevelTypeEnum = LevelTypeEnum;
  readonly productItemMenuConfig = PRODUCT_ITEM_MENU_CONFIG;
  readonly productItemsTableConfig = PRODUCT_ITEMS_TABLE_CONFIG;
  addonQueryForm: FormGroup = new FormGroup({});
  addonLevel: LevelModel;
  addonLevelProductTrees: TableDataSource<ProductTreeList>;
  displayAddItemsButton = false;
  displayAddAddonsButton = false;
  searchParam: ItemsQueryModel
  currentProduct: ModifiedProductTreeWithRegions;
  currentProductItems: TableDataSource<ProductItem>;
  level: LevelModel;
  state$: Observable<ProductsStoreState>;
  itemsSearchFormControl = new FormControl();
  displayedTabs: { label: string | MultiLanguages; id: string }[] = [
  	{
  		label: 'LABEL_PRODUCT_ITEMS',
  		id: 'product_items',
  	},
  ];
  private currentProductId: string;
  private prevPageIndex = 0;

  @ViewChild(SfTabBarComponent) sfTabBar: SfTabBarComponent;

  constructor(private productFacadeService: ProductFacadeService, private activateRoute: ActivatedRoute, private route: Router) {
  	super();
  	this.productFacadeService.initialize();
  	this.state$ = this.productFacadeService.stateChange();
  	this.configureForm(ProductFormBuilder.queryItemInitializer());
  }

  ngOnInit(): void {
  	this.getRouteParam();
  	this.queryChanges();
  	this.listenToItemsQueryChange();
  	this.buildAddonQueryForm();
  	this.addonQueryChanges();
  	this.listenToAddonChanges();
  	this.searchParamChange();
  }

  searchParamChange(): void {
  	this.productFacadeService.specificStateChange<ItemsQueryModel>
  	(ProductStateEnum.ITEMS_QUERY).pipe(
  		debounceTime(300), tap((param: ItemsQueryModel) => this.searchParam = param),
  		untilDestroyed(this)).subscribe();
  }

  buildAddonQueryForm(): void {
  	const addonFormSchema: ReactiveFormSchema = {
  		formModel: ProductTreesQueryModel,
  		skipRequiredValidators: [...Object.keys(new ProductTreesQueryModel())]
  	}
  	this.addonQueryForm = this.formBuilder(addonFormSchema);
  }

  addonQueryChanges(): void {
  	this.addonQueryForm.get('query').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
  		this.productFacadeService.updateSpecificState<ProductTreesQueryModel>(
  			{
  				query: value,
  				ancestry: this.currentProduct.ancestry + '/' + this.currentProduct.id,
  				ancestryDepth: this.addonLevel.rank
  			}, ProductStateEnum.PRODUCT_TREES_QUERY);
  	});
  }

  listenToAddonChanges(): void {
  	this.productFacadeService.specificStateChange<ProductTreesQueryModel>(ProductStateEnum.PRODUCT_TREES_QUERY)
  		.pipe(untilDestroyed(this)).subscribe(value => {
  			this.loadAddonProductTrees(value);
  		});
  	this.productFacadeService.specificStateChange<TableDataSource<ProductTreeList>>(ProductStateEnum.PRODUCT_TREES)
  		.pipe(untilDestroyed(this)).subscribe(productTrees => {
  			this.addonLevelProductTrees = productTrees;
  			this.displayAddAddonsButton = this.addonLevelProductTrees?.collection?.length < 1;
  		})
  }

  queryChanges(): void {
  	this.props('query').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
  		this.productFacadeService.updateSpecificState<ItemsQueryModel>(
  			{query: value, packageId: this.currentProduct?.packageId },
  			ProductStateEnum.ITEMS_QUERY);
  	});
  }

  listenToItemsQueryChange(): void {
  	this.productFacadeService.specificStateChange<ItemsQueryModel>(ProductStateEnum.ITEMS_QUERY).pipe(debounceTime(200),
  		switchMap((query) => this.fetchItemsFromQuery(query)),
  		untilDestroyed(this)).subscribe();
  }

  private fetchItemsFromQuery(query?: ItemsQueryModel, pageEvent?: PageEvent): Observable<TableDataSource<ProductItem>> {
  	return this.productFacadeService
  		.getProductItems({
  			perPage: 10,
  			...query,
  			packageId: this.currentProduct?.packageId
  		})
  		.pipe(
  			tap((res) => {
  				if (pageEvent) this.prevPageIndex = pageEvent.pageIndex;
  				this.currentProductItems = res;
  				this.displayAddItemsButton = (this.currentProduct?.ancestryDepth !== this.addonLevel?.rank ?
  					!this.currentProductItems?.collection?.length : true);
  			})
  		)}

  getRouteParam(): void {
  	this.activateRoute.paramMap.pipe(untilDestroyed(this)).subscribe((res) => {
  		this.currentProductId = res?.['params']?.id;
  		this.getLevels()
  			.pipe(
  				switchMap(() => this.getProductById()),
  				untilDestroyed(this)
  			)
  			.subscribe();

  		this.props('query').valueChanges
  			.pipe(
  				debounceTime(200),
  				switchMap((query) => this.fetchItemsFromQuery({query: query, perPage: 10})),
  				untilDestroyed(this)
  			)
  			.subscribe();
  	});
  }

  createEditItem(itemId = ''): void {
  	this.route.navigateByUrl(`/product-configurator/product/item-create/${this.currentProductId}/${itemId}`).then();
  }

  openStepperFromProduct(): Observable<ParamMap> {
  	return this.activateRoute.paramMap.pipe(
  		tap((res) => {
  			this.currentProductId = res?.['params']?.id;
  		})
  	);
  }

  getLevels(): Observable<LevelModel[]> {
  	return this.productFacadeService.getConfiguration().pipe(
  		switchMap(() => this.productFacadeService.getLevels()),
  		tap((response) => this.getLevel(response))
  	);
  }

  getLevel(levels: LevelModel[]): void {
  	this.level = levels?.filter((level) => level.type === LevelTypeEnum.Product)[0];
  	this.addonLevel = levels?.find((level) => level.type === LevelTypeEnum.Addon);
  }

  getProductById(): Observable<TableDataSource<ProductItem>> {
  	return this.productFacadeService.getProductTreeById(this.currentProductId).pipe(
  		tap((response) => {
			  this.currentProduct = this.productFacadeService.mapProductPackageWithRegion(response, this.level);
  			if (this.canPushAddonsTab()) {
  				this.displayedTabs.push({
  					label: 'LABEL_ADDONS',
  					id: 'addons',
  				});
  				this.loadAddonProductTrees();
  			} else {
  				this.sfTabBar.activateTab(0);
  				this.displayedTabs = this.displayedTabs.filter((tab) => tab.id !== 'addons');
  			}
  		}),
  		switchMap(() => this.fetchItemsFromQuery())
  	);
  }

  private loadAddonProductTrees(queryParam?: ProductTreesQueryModel): void {
  	this.productFacadeService
  		.getProductTrees({
  			...queryParam,
  			ancestry: this.currentProduct.ancestry + '/' + this.currentProduct.id,
  			ancestryDepth: this.addonLevel.rank,
  		},
  		false
  		)
  		.subscribe((connection) => {
  			this.addonLevelProductTrees = connection;
  			this.displayAddAddonsButton = this.addonLevelProductTrees?.collection?.length < 1;
  		});
  }

  private canPushAddonsTab(): boolean {
  	return (
  		this.addonLevel &&
      this.displayedTabs.every((tab) => tab.id !== 'addons') &&
      this.currentProduct.ancestryDepth !== this.addonLevel.rank
  	);
  }

  cloneProductItem(itemId: string): void {
  	this.productFacadeService
  		.cloneProductItemById(itemId)
  		.pipe(switchMap(() => this.fetchItemsFromQuery()))
  		.subscribe();
  }

  setProductItemActiveStatus(itemId: string, active: boolean): void {
  	this.productFacadeService
  		.setProductItemActiveStatus(itemId, active)
  		.pipe(switchMap(() => this.fetchItemsFromQuery()))
  		.subscribe();
  }

  removeProductItem(itemId: string): void {
  	this.productFacadeService
  		.removeProductItem(itemId)
  		.pipe(switchMap(() => this.fetchItemsFromQuery()))
  		.subscribe();
  }

  handleProductItemsTableAction(actionEvent: { actionKey: string; row: ProductItemsList }): void {
  	const actionMap: Record<string, (row: ProductItemsList) => void> = {
  		clone: (row) => this.cloneProductItem(row.id),
  		edit: (row) => this.createEditItem(row.id),
  		changeStatus: (row) => this.setProductItemActiveStatus(row.id, row.active),
  		delete: (row) => this.removeProductItem(row.id),
  	};
  	const targetFn = actionMap[actionEvent.actionKey];
  	if (targetFn) actionMap[actionEvent.actionKey].bind(this)(actionEvent.row);
  }

  updateProductItemsTableQuery(searchParamModel?: ItemsQueryModel): void {
  	this.productFacadeService.updateSpecificState(
  		{...searchParamModel, packageId: this.currentProduct?.packageId}, ProductStateEnum.ITEMS_QUERY);
	  // TODO JIMBA - removed for now
  	// this.productFacadeService.getProductTressByAncestryData(this.currentProduct?.ancestry, this.currentProduct?.ancestryDepth);
  }
}
