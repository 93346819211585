import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiLangComponent } from './multi-lang/multi-lang.component';
import {
    SfChipsModule,
    SfFormsModule,
    SfIconButtonModule,
    SfIconModule,
    SfInputModule,
    SfTypographyModule
} from '@safire/components';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        SfChipsModule,
        SfIconModule,
        SfFormsModule,
        ReactiveFormsModule,
        SfInputModule,
        TranslateModule,
        FlexLayoutModule,
        SfTypographyModule,
        SfIconButtonModule
    ],
    declarations: [
        MultiLangComponent
    ],
    exports: [MultiLangComponent]
})
export class SharedMultiLangModule {}
