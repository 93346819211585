import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageSwitcherComponent} from './components/language-switcher/language-switcher.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SfButtonModule, SfcMenuSurfaceModule, SfIconModule, SfMenuModule} from '@safire/components';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, SfcMenuSurfaceModule, SfButtonModule, SfIconModule, SfMenuModule, TranslateModule],
    declarations: [
        LanguageSwitcherComponent
    ],
    exports: [
        LanguageSwitcherComponent
    ]
})
export class SharedComponentsLanguageSwitcherModule {
}
