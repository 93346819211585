import { Component, OnInit } from '@angular/core';
import { NotificationSettingFormBuilder } from '@ew/shared/models';
import { NotificationService, SharedFacadeService } from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@safire/components';
import { Subject } from 'rxjs';
import { EmailRedirectionAuthenticatorUpdate } from '../../models/push-notification.model';
import { Validator } from '@safire/angular-utils';

@Component({
    selector: 'ew-add-notification-email',
    templateUrl: './add-notification-email.component.html',
    styleUrls: ['./add-notification-email.component.scss']
})
export class AddNotificationEmailComponent extends FormBaseComponent implements OnInit {

    step = EmailRedirectionAuthenticatorUpdate.UPDATE_PAGE;
    stepPage = EmailRedirectionAuthenticatorUpdate;
    otpVals!: string;
    restartTimerSubject$: Subject<void> = new Subject<void>();
    isLoading!: boolean;

    constructor(private dialogRef: DialogRef<AddNotificationEmailComponent>, private sharedFacade: SharedFacadeService,
        private notify: NotificationService,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializer();
    }

    initializer(): void {
        this.configureForm(NotificationSettingFormBuilder.NotificationEmailModeFormInitializer());
        this.form.get('unconfirmedEmail').setValidators([Validator.emailValidator()]);
        this.form.get('unconfirmedEmail').updateValueAndValidity();
    }

    subimt(): void {  
        this.isLoading = true;
        this.sharedFacade.updateUserEmailOrPhone({unconfirmedEmail: this.emailFormValue}).subscribe(
            () => {
                this.isLoading = false;
                this.step = EmailRedirectionAuthenticatorUpdate.OTP_PAGE;
            });
    }

    cancel(value?: boolean): void {
        this.dialogRef.close(value);
    }

    update(): void {
        this.isLoading = true;
        this.sharedFacade.confirmation(this.otpVals).subscribe( () => { 
            this.notify.show(this.translate.instant('SUCCESS_EMAIL_UPDATED'));
            this.isLoading = false;
            this.cancel(true);
        });
    };

    goToPrevPage(): void {
        this.step = EmailRedirectionAuthenticatorUpdate.UPDATE_PAGE
    };
  
    getOtpNumbers(event: string): void {
        this.otpVals = event
    };

    resendPin(): void {
        this.restartTimer();
        this.sharedFacade.resendOTPCode({email: this.emailFormValue}).subscribe();
    };

    restartTimer(): void { 
        this.restartTimerSubject$.next();
    }

    private get emailFormValue(): string {
        return this.form.get('unconfirmedEmail')?.value
    };
}
