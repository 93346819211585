import {Component, Inject, Optional} from '@angular/core';
import {DialogRef, SfDialogConfig} from '@safire/components';
import {FormControl, FormGroup} from '@angular/forms';
import {ProductFacadeService} from '../../../services/product-facade.service';
import {ControlParams, NotificationService, PackageRegionEnum, ProductStateEnum} from '@ew/shared/services';
import {PACKAGE_REGION_CHECKBOX_OPTIONS, activeStatusParams} from '../../../constants/query.constant';

@Component({
    selector: 'ew-product-filter-modal',
    templateUrl: './product-filter-modal.component.html',
    styleUrls: ['./product-filter-modal.component.scss']
})
export class ProductFilterModalComponent {
    form: FormGroup = this.config?.data?.form;
    controlParams: ControlParams[] = activeStatusParams;
    regioncheckboxOptions:  {label: string, value: PackageRegionEnum}[] = PACKAGE_REGION_CHECKBOX_OPTIONS;

    constructor(private ref: DialogRef<string>, private productsFacadeService: ProductFacadeService,
        private notifiy: NotificationService,
                @Optional() @Inject(SfDialogConfig) public config: { data: { form: FormGroup, shouldDisplayCheckboxFilter: boolean } }) {
    }

    applyFilter(): void {
        if (this.form.get('filter')?.value?.length) {
            this.productsFacadeService.updateSpecificState(this.form.value, ProductStateEnum.PRODUCT_TREES_QUERY);
            this.ref.close(true);
            return;
        }
        this.notifiy.show('ERROR_CHECKBOX_SELECT', 'error');
    }

    closeModal(): void {
    	this.ref.close(false);
    }

    resetFilters(): void {
    	this.form?.get('active').reset();
        this.form?.get('filter').patchValue(this.regioncheckboxOptions.map(regionCheckboxOption => regionCheckboxOption.value));
    	this.applyFilter();
    }

    get filter(): FormControl {
        return this.form.get('filter') as FormControl;
    }

    get active(): FormControl {
    	return this.form.get('active') as FormControl;
    }
}
