import { Component, EventEmitter, Output } from '@angular/core';
import { SfDialogService } from '@safire/components';
import {ConstantPreferenceComponent} from './constant-preference/constant-preference.component';
import { GAConsentPermissionEnum, SharedFacadeService, SharedStoreStateEnum } from '@ew/shared/services';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'ew-cookie-bar',
    templateUrl: './cookie-bar.component.html',
    styleUrls: ['./cookie-bar.component.scss']
})
export class CookieBarComponent {

    @Output() cookieBarEmittedValue: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private dialogService: SfDialogService, private sharedFacade: SharedFacadeService,
        private cookieService: CookieService) {
    }

    showConstantModal(): void {
        this.cookieBarEmittedValue.emit(false);
        this.dialogService.open(ConstantPreferenceComponent, {
            maxWidth: 800,
            panelClass: 'common-modal--width'
        }).afterClosed.subscribe(() => this.displayCookiebarState(true));
    }

    displayCookiebarState(value: boolean): void {
        this.sharedFacade.updateSpecificState(value, SharedStoreStateEnum.COOKIE_BAR_MODAL_DISPLAY);
    }

    acceptCookieBar(): void {
        if (!this.cookieService.get('_google_analytics_accepted')) {
            this.setCookie('_google_analytics_accepted', GAConsentPermissionEnum.granted);
            this.sharedFacade.updateGoogleAnalyticsConsentData(GAConsentPermissionEnum.granted);
        } 
        this.setCookie('_cookie_consent_accepted', GAConsentPermissionEnum.granted);
        this.cookieBarEmittedValue.emit(false);
    }

    rejectCookieBar(): void {
        ['_cookie_consent_accepted', '_google_analytics_accepted'].forEach(cookieType => 
            this.cookieService.set(cookieType, GAConsentPermissionEnum.denied));
        this.cookieBarEmittedValue.emit(false);
    }

    navigateToExternalPrivacyPolicy(): void {
        window.open('https://ewbuchs.ch/datenschutz/', '_blank');
    }

    setCookie(cookieType: string, permissionType: GAConsentPermissionEnum): void {
        this.cookieService.set(cookieType, permissionType, this.sharedFacade.getAnalyticsCookieExpiryDate());
    }
}
