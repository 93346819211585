import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductBaseComponent} from './components/product-base/product-base.component';
import {ProductListComponent} from './components/product/product-list/product-list.component';
import {ServiceListComponent} from './components/service/service-list/service-list.component';
import {ServiceCreateModalComponent} from './components/service/service-create-modal/service-create-modal.component';
import {AddonListComponent} from './components/addon/addon-list/addon-list.component';
import {AddonCreateModalComponent} from './components/addon/addon-create-modal/addon-create-modal.component';
import {ProductFilterModalComponent} from './components/product/product-filter-modal/product-filter-modal.component';
import {ProductConfirmationModalComponent} from './components/product/product-confirmation-modal/product-confirmation-modal.component';
import {
    SfAccordionModule,
    SfButtonModule,
    SfButtonToggleModule,
    SfCardModule,
    SfCheckboxModule,
    SfChipsModule,
    SfDataTableModule,
    SfDialogModule,
    SfElevationModule,
    SfIconButtonModule,
    SfIconModule,
    SfInputModule,
    SfMenuModule,
    SfPaginationModule,
    SfRadioModule,
    SfSelectModule,
    SfStepperModule,
    SfTabBarModule,
    SfTypographyModule
} from '@safire/components';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {SearchFilterComponent} from './components/product/search-filter/search-filter.component';
import {ServiceDetailsComponent} from './components/service/service-details/service-details.component';
import {SfColorPickerModule} from '@safire/color-picker';
import {ProductNodeListModalComponent} from './components/service/product-node-list-modal/product-node-list-modal.component';
import {PackageListComponent} from './components/package/package-list/package-list.component';
import {SharedPipeTranslateNameModule} from '@ew/shared/pipe/translate-name';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicModule} from 'ng-dynamic-component';
import {SharedComponentsSearchBarModule} from '@ew/shared/components/search-bar';
import {SharedComponentsFilterModule} from '@ew/shared/components/filter';
import {SharedServicesModule} from '@ew/shared/services';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {SharedComponentsTabularViewModule} from '@ew/shared/components/tabular-view';
import {LevelResolverComponent} from './components/product/level-resolver/level-resolver.component';
import {LevelSettingComponent} from './components/product/level-setting/level-setting.component';
import {ItemCreationComponent} from './components/item/item-creation/item-creation.component';
import {ItemListingComponent} from './components/item/item-listing/item-listing.component';
import {SharedComponentsMenuModalModule} from '@ew/shared/components/menu-modal';
import {VerticalStepperComponent} from './components/stepper/vertical-stepper/vertical-stepper.component';
import {ProductDetailsStepComponent} from './components/stepper/steps/product-details-step/product-details-step.component';
import {OptionSetStepComponent} from './components/stepper/steps/option-set-step/option-set-step.component';
import {StepperComponent} from './components/stepper/stepper/stepper.component';
import {PricingDetailsStepComponent} from './components/stepper/steps/pricing-details-step/pricing-details-step.component';
import {OtherDetailsStepComponent} from './components/stepper/steps/other-details-step/other-details-step.component';
import {OptionSetModalComponent} from './components/stepper/steps/option-set-modal/option-set-modal.component';
import {PrevNextComponent} from './components/stepper/common/prev-next.component';
import {SharedMultiLangModule} from '@ew/shared/components/multi-lang';
import {SharedComponentsDynamicFieldModule} from '@ew/shared/components/dynamic-field';
import {SharedComponentsImagePickerModule} from '@ew/shared/components/image-picker';
import {ContractDurationChipsComponent} from './components/contract-duration-chips/contract-duration-chips.component';
import {ContractDurationComponent} from './components/contract-duration/contract-duration.component';
import {PricingComponent} from './components/item/item-creation/pricing/pricing.component';
import {SharedPipeTruncateModule} from '@ew/shared/pipe/truncate';

@NgModule({
    imports: [
        CommonModule,
        SfCardModule,
        SfIconModule,
        SfChipsModule,
        SfTypographyModule,
        SfTabBarModule,
        SfInputModule,
        SfButtonModule,
        SfDialogModule,
        SfMenuModule,
        SfIconButtonModule,
        SfAccordionModule,
        SfRadioModule,
        SfStepperModule,
        SfSelectModule,
        SfCheckboxModule,
        SfElevationModule,
        SfColorPickerModule,
        SfDataTableModule,
        SfElevationModule,
        FlexLayoutModule,
        TranslateModule,
        SfDataTableModule,
        SfCheckboxModule,
        SfPaginationModule,
        SfButtonToggleModule,
        SharedPipeTranslateNameModule,
        ReactiveFormsModule,
        SharedComponentsSearchBarModule,
        SharedComponentsFilterModule,
        FormsModule,
        DynamicModule,
        SfDataTableModule,
        SfCheckboxModule,
        SfPaginationModule,
        SfButtonToggleModule,
        SharedServicesModule,
        SharedComponentsTabularViewModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        SharedComponentsMenuModalModule,
        DynamicModule,
        DynamicModule.withComponents([
            ProductDetailsStepComponent,
            PricingDetailsStepComponent,
            OtherDetailsStepComponent,
            OptionSetModalComponent,
        ]),
        SharedMultiLangModule,
        SharedComponentsDynamicFieldModule,
        SharedComponentsImagePickerModule,
        SharedPipeTruncateModule,
    ],
    declarations: [
        ProductBaseComponent,
        ProductListComponent,
        ServiceListComponent,
        ServiceCreateModalComponent,
        AddonListComponent,
        AddonCreateModalComponent,
        ProductFilterModalComponent,
        SearchFilterComponent,
        ProductConfirmationModalComponent,
        ServiceDetailsComponent,
        PackageListComponent,
        ProductNodeListModalComponent,
        LevelResolverComponent,
        LevelSettingComponent,
        ItemCreationComponent,
        ItemListingComponent,
        VerticalStepperComponent,
        ProductDetailsStepComponent,
        OtherDetailsStepComponent,
        PricingDetailsStepComponent,
        OptionSetStepComponent,
        OptionSetModalComponent,
        StepperComponent,
        ContractDurationChipsComponent,
        ContractDurationComponent,
        PrevNextComponent,
        PricingComponent
    ]
})
export class PcProductsModule {
}
