import {Component, Inject, OnInit} from '@angular/core';
import {I18N_CONFIG, I18nConfig, LanguageType} from '@safire/i18n';
import {TranslateService} from '@ngx-translate/core';
import {LanguageSwitcherFacadeService} from '../../services/language-switcher-facade.service';
import { Environment, ENV_STORAGE } from '@ew/shared/services';

@Component({
    selector: 'ew-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

	languages?: LanguageType[] = [];
	currentLang: string;

	constructor(public translate: TranslateService, @Inject(I18N_CONFIG) i18nConfig: I18nConfig,
		@Inject(ENV_STORAGE) private environment: Environment, 
	            private langSwitcher: LanguageSwitcherFacadeService) {
	    this.languages = i18nConfig.languages;
	}

	ngOnInit(): void {
	    this.checkForSetLang();
	    this.currentLang = this.langSwitcher.getCurrentLang() || this.environment.defaultLanguage;
	    this.translate.use(this.currentLang);
	}

	checkForSetLang(): void {
	    if (!this.langSwitcher.getCurrentLang()) {
	        this.langSwitcher.setCurrentLang(this.translate.currentLang);
	    }
	}

	toggleLang = (language: { source: { value: string; }; }): void => {
	    this.currentLang = language.source.value;
	    this.translate.use(this.currentLang);
	};
}
