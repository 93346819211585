import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SfFormUtil } from '@safire/angular-utils';
import { DialogRef, SfCheckboxChange, SfDialogConfig, SfSnackBarService } from '@safire/components';
import { ControlOptions, DynamicColumnAttributes, MultiLanguages, ProductFormBuilder } from '@ew/shared/services';
import { keys } from 'lodash-es';

import { dynamicControls, staticColor } from '../../../../constants/products.constant';
import { ProductBaseComponent } from '../../../product-base/product-base.component';

@Component({
    selector: 'ew-option-set-modal',
    templateUrl: './option-set-modal.component.html',
    styleUrls: ['./option-set-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionSetModalComponent extends ProductBaseComponent implements OnInit {
  languages: string[] = keys(new MultiLanguages());
  colorSets: string[] = staticColor;
  selectBgColor: string[] = [];
  isConfigured: boolean;
  showColorPicker: boolean;
  dynamicColumnAttributes: DynamicColumnAttributes[] = [];
  currentIndexFromOptionSetStep: number;
  isEdit: boolean;
  isShowDynamicOptionError: boolean;
  copyControls: ControlOptions[] = dynamicControls;
  selectedLanguageControl = new FormControl(this.translate.currentLang);
  dynamicColumnForm: FormGroup;

  constructor(
    private ref: DialogRef<OptionSetModalComponent>,
    private translate: TranslateService,
    private notify: SfSnackBarService,
    @Optional()
    @Inject(SfDialogConfig)
    public config: { data: { config: { dynamicColumns: DynamicColumnAttributes[] }; form: FormGroup; index: number; type: string } }
  ) {
  	super();
  }

  ngOnInit(): void {
  	this.dynamicColumnAttributes = this.config?.data.config?.dynamicColumns || [];
  	this.isEdit = this.config?.data.type === 'edit';
  	this.form = this.config.data.form;
  	this.valueChange();
  	this.dynamicColumnForm = this.formBuilder(ProductFormBuilder.dynamicColumnInitializer());
  	this.onEditFromOptionSetStep();
  }

  pushDynamicColumnToForm(index?: number): void {
  	index == null
  		? this.dynamicColumnFormArray.push(this.dynamicColumnForm)
  		: this.dynamicColumnFormArray.at(index).patchValue(this.dynamicColumnForm.value);
  	this.dynamicColumnFormArray.updateValueAndValidity();
  }

  updateValueForDynamicOptions(): void {
  	this.dynamicOptionsControls.updateValueAndValidity();
  	this.dynamicColumnForm.updateValueAndValidity();
  	SfFormUtil.markAllFormFieldsAsTouched(this.form);
  	SfFormUtil.focusOnErrorElement();
  }

  setControlsForThisColumnOption(controlType: string, isColorOptionTye: boolean): void {
  	if (controlType === 'dataType') this.dynamicColumnForm.get(controlType).setValue(isColorOptionTye ? 'color' : 'options');
  	if (controlType === 'dataType') this.dynamicColumnForm.get(controlType).setValidators([Validators.required]);
  	this.dynamicColumnForm.updateValueAndValidity();
  }

  setControlsForThisOptions(controlType: string, optionIndex?: number, controlValue?: string): void {
  	const controlInContext = this.dynamicOptionsControls.at(optionIndex).get(controlType);
  	controlInContext.setValue(controlValue);
  	controlInContext.updateValueAndValidity();
  }

  setDefaultHexControl(): void {
  	(this.dynamicColumnForm.get('dynamicOptions') as FormArray).controls.forEach((opt) => {
  		opt.get('hexValue').setValue(null);
  	});
  }

  onChangeSetColorOption(event: SfCheckboxChange): void {
  	this.showColorPicker = !this.showColorPicker;
  	if (event.checked) {
  		this.setControlsForThisColumnOption('dataType', event.checked);
  	} else {
  		this.setControlsForThisColumnOption('dataType', false);
  		this.setDefaultHexControl();
  	}
  }

  onColorChangeSetHexValue(optionIndex: number, colorHexValue: string): void {
  	this.setControlsForThisOptions('hexValue', optionIndex, colorHexValue);
  }

  buildOptionColumnAttributes(initialIndex?: number): void {
  	this.dynamicColumnForm.patchValue(this.dynamicColumnFormArray.at(initialIndex)?.value || {});
  	this.checkDynamicOptionAttr();
  	this.dynamicOptionsControls.push(this.formBuilder(ProductFormBuilder.dynamicOptionInitializer()));
  	this.dynamicOptionsControls.updateValueAndValidity();
  	this.checkDynamicOptionAttr();
  }

  setOptionsFromEdit(): void {
  	this.dynamicColumnAttributes.forEach((col, colIndex) => {
  		col.dynamicOptions.forEach(() => this.buildOptionColumnAttributes(colIndex));
  	});
  }

  onEditFromOptionSetStep(): void {
  	if (this.isEdit) {
  		this.setOptionsFromEdit();
  		this.dynamicColumnForm.patchValue(this.dynamicColumnAttributes[this.config.data.index]);
  	}
  }

  addDynamicOptionSet(): void {
  	this.updateValueForDynamicOptions();
  	this.checkDynamicOptionAttr();
  	if (this.dynamicColumnForm.valid) {
  		this.pushDynamicColumnToForm();
  		this.ref.close({ add: this.form.value });
  	}
  }

  checkDynamicOptionAttr(): void {
  	this.isShowDynamicOptionError = !(this.dynamicColumnForm.get('dynamicOptions') as FormArray).controls.length;
  }

  editDynamicOptionSet(): void {
  	this.checkDynamicOptionAttr();
  	this.dynamicOptionsControls.updateValueAndValidity();
  	if (this.dynamicColumnForm.valid) {
  		const dynamicColumnIndex = this.config.data.index;
  		this.pushDynamicColumnToForm(dynamicColumnIndex);
  		this.ref.close({
  			edit: this.form.value,
  			index: dynamicColumnIndex,
  		});
  	}
  }

  copyContent(): void {
  	Object.keys(new MultiLanguages()).forEach((langKey) => {
  		this.dynamicColumnForm
  			.get('name')
  			.get(langKey)
  			.patchValue(this.dynamicColumnForm.get('name').get(this.selectedLanguageControl.value).value);
  	});

  	this.dynamicOptionsControls.controls.forEach((group) => {
  		Object.keys(new MultiLanguages()).forEach((langKey) => {
  			group.get('name').get(langKey).patchValue(group.get('name').get(this.selectedLanguageControl.value).value);
  		});
  	});

  	this.notify.open(this.translate.instant('HINT_COPY_NAME_DESCRIPTION'), undefined, 'success', {
  		duration: 1000,
  		verticalPosition: 'top',
  		horizontalPosition: 'center',
  	});
  }

  cancel(): void {
  	this.ref.close(false);
  }

  getControl(type: string, lang: string, columnControl: FormGroup): FormControl {
  	return columnControl.get(type + '.' + lang) as FormControl;
  }

  errorLanguages(type: string, columnControl: FormGroup): string[] {
  	return this.languages.filter(
  		(lang) => this.getControl(type, lang, columnControl)?.invalid && this.getControl(type, lang, columnControl)?.touched
  	);
  }

  errorLanguagesForOptions(type: string, optionControl: FormGroup): string[] {
  	return this.languages.filter(
  		(lang) => this.getControlOption(type, lang, optionControl)?.invalid && this.getControlOption(type, lang, optionControl)?.touched
  	);
  }

  getControlOption(type: string, lang: string, optionControl: FormGroup): FormControl {
  	return optionControl.get(type + '.' + lang) as FormControl;
  }

  selectLanguage(language: string): void {
  	this.selectedLanguageControl.setValue(language);
  }

  removeOption(index?: number): void {
  	this.dynamicOptionsControls.removeAt(index);
  	this.updateValueForDynamicOptions();
  }

  get dynamicOptionsControls(): FormArray {
  	return this.dynamicColumnForm.get('dynamicOptions') as FormArray;
  }

  private get dynamicColumnFormArray(): FormArray {
  	return this.form.get('dynamicColumns') as FormArray;
  }
}
