import {Component, EventEmitter, Input, OnInit, Output, ViewChild, Inject} from '@angular/core';
import {ADMIN_ROLE_SELECT_CONST, AdminRoleType, NotificationSortEnum, NotificationSortHeader, 
    PCBreadcrumb, ProductStateEnum, PushNotificaionService, SharedFacadeService, SharedStoreStateEnum, User} from '@ew/shared/services';
import {Event, NavigationEnd, Router, RouterEvent} from '@angular/router';
import { RoleEnum, SecurityFacadeService } from '@ew/shared/security';
import {Observable} from 'rxjs';
import {SfBreadcrumbComponent, SfDialogService} from '@safire/components';
import {ProductFacadeService} from '@ew/pc/products';
import {filter, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { PROFILE_ROLE_NAME } from '@ew/shared/models';
import { DOCUMENT } from '@angular/common';
import { LanguageSwitcherFacadeService } from '@ew/shared/components/language-switcher';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
    NotificationLandingComponent
} from '../../../../../components/notification/src/lib/notification-landing/notification-landing.component';
import { cloneDeep } from '@apollo/client/utilities';

@UntilDestroy()
@Component({
    selector: 'ew-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    isAdmin: boolean;
    isGroupAdmin: boolean;
    roleName: string;
    isUser!: boolean;
    totalUnreadNotificationCount!: number;
    totalUnreadNotificationCountLength!: number;
    breadCrumbs: Observable<PCBreadcrumb[]> = this.pcFacade.specificStateChange<PCBreadcrumb[]>(ProductStateEnum.BREADCRUMBS);
    profile$: Observable<User> = this.sharedFacade.specificStateChange<User>(SharedStoreStateEnum.PROFILE_STATE);
    currentUser!: User;
    adminRoleOptions: AdminRoleType[] = cloneDeep(ADMIN_ROLE_SELECT_CONST);
    currentUserAdminRoleOptions!:  AdminRoleType[];

    @Input() header: string;
    @Output() menuToggle = new EventEmitter<boolean>();
    @ViewChild('el') el: SfBreadcrumbComponent;

    constructor(
        private sharedFacade: SharedFacadeService,
        private dialogService: SfDialogService,
        private pcFacade: ProductFacadeService,
        private translate: TranslateService,
        private route: Router,
        private securityFacade: SecurityFacadeService, @Inject(DOCUMENT)
        private document: Document,
        private languageSwitcherFacadeService: LanguageSwitcherFacadeService,
        private notificationService: PushNotificaionService,
    ) {
    }

    ngOnInit(): void {
        this.initializer();
    }

    initializer(): void {
        this.listenToNotificationCountChange();
        this.sharedFacade.getUserDetails().pipe(
            tap(user => {
                this.isAdmin = user?.role?.name === RoleEnum.ADMIN_ROLE;
                this.roleName = PROFILE_ROLE_NAME[user.role.name];
                this.isUser = user?.role?.name === RoleEnum.USER;
                this.currentUser = user;
                this.setAdminEnumRole();
            })
        ).subscribe();
        this.clearPCBreadcrumb();
        this.setHeader();
        this.translate.onLangChange.pipe(tap(() => this.translateBreadcrumb()), untilDestroyed(this)).subscribe();
        this.sharedFacade.getNotificationsCount().subscribe();
    }

    setAdminEnumRole(): void {
        this.currentUserAdminRoleOptions = 
            this.adminRoleOptions.filter(admin => this.currentUser.adminRoles.some(role => role?.roleName === admin?.id)).filter(
                role => role.id !== this.currentUser.role?.name
            );
    }

    listenToNotificationCountChange(): void {
        this.sharedFacade.specificStateChange<NotificationSortHeader[]>(SharedStoreStateEnum.NOTIFICATIONS_LISTS).pipe(
            untilDestroyed(this),
            tap(notificationCount => {
                this.totalUnreadNotificationCount = 
                    notificationCount.find(notification => notification?.value === NotificationSortEnum.UNREAD)?.totalCount;
                this.totalUnreadNotificationCountLength = this.totalUnreadNotificationCount?.toString()?.length;;

            })
        ).subscribe();    
    }

    translateBreadcrumb(): boolean {
        this.el?.breadcrumbs?.forEach(data => {
            !data['labelKey'] && (data['labelKey'] = data.label);
            data.label = this.translate.instant(data['labelKey']);
        });
        return true;
    }

    navigate(crumb: PCBreadcrumb): void {
        const existingBreadcrumbs = this.pcFacade.getSpecificState<PCBreadcrumb[]>(ProductStateEnum.BREADCRUMBS);
        const newBreadCrumbsLength = existingBreadcrumbs?.findIndex(crumbs => crumbs.url === crumb.url);
        if (newBreadCrumbsLength >= 0) {
            existingBreadcrumbs.length = newBreadCrumbsLength + 1;
            this.pcFacade.updateSpecificState<PCBreadcrumb[]>(existingBreadcrumbs, ProductStateEnum.BREADCRUMBS);
        }
        this.route.navigateByUrl(crumb?.url);
    }

    logout(): void {
        const token = this.sharedFacade.getFirebaseToken();
        //firebase token will be there for users only thus trigger for users only
        (this.isUser && !!token) ? this.removeFirebaseToken(token) : this.logoutUser();
    }

    removeFirebaseToken(token: string): void {
        this.notificationService.deleteRegistrationFirebaseToken(token).subscribe(
            () => { this.sharedFacade.removeFirebaseToken(); this.logoutUser(); }
        );
    }

    logoutUser(): void {
        this.sharedFacade.logout(true).subscribe(
            () => {
                this.securityFacade.initialize();
                this.document.querySelector('.grecaptcha-badge')?.classList.remove('hide-visibility');
                this.document.getElementById('cookie-bar-container')?.classList.add('hide-visibility');
                //TODO: Might need in the future
                /*This is giving issue in wrapper so hiding this redirection as we discuss with BA
                this.isAdmin ? this.route.navigate(['/auth/login/admin']) : */
                this.route.navigate(['/auth/login']);
                this.languageSwitcherFacadeService.setDefaultLanguage();
                //TODO: might need in the future
                // this.pushNotificationService.inValidateFirebaseToken(token);
                //clear state so that when you refresh the page, the saveFirebaseToken will not get trigerred in app component in logout
                this.sharedFacade.updateSpecificState(null, SharedStoreStateEnum.PROFILE_STATE);
            }
        );
    }

    setHeader = (): void => {
        this.header = window.location.pathname.replace('-', '_').match(/(\/\w*)/g)?.[0].replace('/', '');
    };

    clearPCBreadcrumb(): void {
        this.route.events.pipe(
            filter((event: Event) => event instanceof NavigationEnd), untilDestroyed(this)).subscribe(
            (route: RouterEvent) => {
                this.updatePCBreadcrumbsWithCorrectRouteValue();
                this.setHeader();
                if (route.url === '/product-configurator/product') {
                    this.pcFacade.updateSpecificState<PCBreadcrumb[]>([], ProductStateEnum.BREADCRUMBS);
                }
            });
    }

    openMenu(toggleValue: boolean):void {
        this.menuToggle.emit(toggleValue);
    }

    showNotification(): void {
        this.dialogService.open(NotificationLandingComponent, {
            width: 400,
            panelClass: 'notification-landing'
        })
    }

    switchRole(userRole: {role: string, label: string, id: string, selected: boolean}): void {
        const roleId = this.currentUser.adminRoles.find(admin => admin?.roleName === userRole.id)?.roleId;
        this.sharedFacade.switchRole(roleId).subscribe(() => this.redirectToProfileModule());
    }

    redirectToProfileModule(): void {
        this.route.navigate(['/profile']).finally(() => window.location.reload());
    }

    //Remove the previous item listing route from array of PCBreadcrumb so that that element can be replaced with new item listing route
    updatePCBreadcrumbsWithCorrectRouteValue(): void {
        const pcBreadcrumbs = this.pcFacade.getSpecificState<PCBreadcrumb[]>(ProductStateEnum.BREADCRUMBS) || [];
        const itemsWithRouteUrlContainItemListing = pcBreadcrumbs.filter(pcBreadcrumb => pcBreadcrumb?.url?.includes('item-listing'));
        if (itemsWithRouteUrlContainItemListing.length > 1) {
            const previousItemListingRoute = pcBreadcrumbs.findIndex(pcBreadcrumb => pcBreadcrumb?.url?.includes('item-listing'));
            //remove the previous item listing route value at its index so that it can be replace with new item listing route value
            pcBreadcrumbs.splice(previousItemListingRoute, 1);
            this.pcFacade.updateSpecificState<PCBreadcrumb[]>(pcBreadcrumbs, ProductStateEnum.BREADCRUMBS);
        }
    }
}