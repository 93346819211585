import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DialogRef, SfDialogConfig } from '@safire/components';
import { Level, LevelTypeEnum } from '@ew/shared/services';

@Component({
    selector: 'ew-addon-configuration-modal',
    templateUrl: './addon-configuration-modal.component.html',
    styleUrls: ['./addon-configuration-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddonConfigurationModalComponent implements OnInit {
  addonLevelData: Level;

  constructor(
    private ref: DialogRef<AddonConfigurationModalComponent>,
    private config: SfDialogConfig<{ addonLevel: Level }>
  ) { }

  ngOnInit(): void {
  	this.addonLevelData = {
  		type: LevelTypeEnum.Addon,
  		...(this.config.data.addonLevel || {})
  	};
  }

  closeModal(save: boolean): void {
  	this.ref.close(save);
  }
}
